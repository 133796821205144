<template>
    <!-- Modal -->
    <div class="modal fade" id="carteraModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Registro de cartera</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Tipo Movimiento</label>
                            <select class="form-control form-control-sm">
                                <option value="ABONO">ABONO</option>
                                <option value="GLOSA">GLOSA</option>
                            </select>
                        </div>
                        <div class="col-6 form-group">
                            <label>Cuenta</label>
                            <v-select-puc></v-select-puc>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Valor</label>
                            <input type="number" class="form-control form-control-sm">
                        </div>
                        <div class="col-6 form-group">
                            <label>Observacion</label>
                            <textarea class="form-control form-control-sm"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary btn-sm">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VSelectPuc from "../../../components/common/VSelectPuc";
import $ from 'jquery';

export default {
    components: {VSelectPuc},
    methods : {
        show(){
            $('#carteraModal').modal('show');
        }
    }
}
</script>
