<template>
    <main>
        <header
            class="page-header page-header-compact page-header-light border-bottom bg-white mb-4"
        >
            <div class="container-fluid">
                <div class="page-header-content">
                    <div class="row align-items-center justify-content-between pt-3">
                        <div class="col-auto mb-3">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"></div>
                                Relacion de Facturas Emitidas
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Main page content-->
        <div class="container-fluid mt-4">
            <div class="card">
                <div class="card-body">
                    <data-grid :data="pagination" @search="search($event)">
                        <template #grid>
                            <grid-table>
                                <template #head>
                                    <grid-cell-header v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                </template>
                                <template #body>
                                    <grid-row class="border-bottom" v-for="(factura, index) in pagination.data" :key="index">
                                        <grid-cell width="25px" class="text-center align-middle">
                                            <input type="checkbox" :key="`check_${index}_${factura.id}`" :id="`check_${index}_${factura.id}`" @click="handleCheck(factura.id, $event)">
                                        </grid-cell>
                                        <grid-cell class="small text-center">{{ factura.prefijo }}{{ factura.numero }}</grid-cell>
                                        <grid-cell>
                                            <span v-if="factura.estado === 'RADICADO'" class="badge badge-success">Radicado</span>
                                            <span v-if="factura.estado === 'NO_RADICADO'" class="badge badge-warning">No Radicado</span>
                                        </grid-cell>
                                        <grid-cell>
                                            <p class="mb-0 small">
                                                {{factura.eps.IDEPS}}
                                                {{factura.eps.DESEPS }}</p>
                                            <p class="mb-1 text-xs">{{ factura.contrato.codigo }} {{ factura.contrato.descripcion }}</p>
                                        </grid-cell>
                                        <grid-cell class="small">
                                            {{ factura.periodo_inicio }} hasta {{ factura.periodo_fin }}
                                        </grid-cell>
                                        <grid-cell class="small">
                                            {{ factura.actividad.descripcion }}
                                        </grid-cell>
                                        <grid-cell class="small">
                                            {{ factura.observaciones }}
                                        </grid-cell>
                                        <grid-cell class="small">
                                            {{ factura.valor_facturado | cop_currency }}
                                        </grid-cell>
                                        <grid-cell>
                                            <button
                                                v-show="factura.id_service === null"
                                                class="btn btn-transparent btn-icon btn-sm"
                                                @click="sendInvoiceEps(factura.id)"
                                                title="Enviar Factura Electronica"
                                            >
                                                <i class="fas fa-upload"></i>
                                            </button>
                                            <button
                                                v-show="factura.id_service !== null"
                                                class="btn btn-transparent btn-icon btn-sm"
                                                @click="verFacturaElectronica(factura.id)"
                                                title="Ver Factura Electronica"
                                            >
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </grid-cell>
                                        <grid-cell>
                                            <button class="btn btn-transparent btn-icon btn-sm" title="Editar factura">
                                                <i class="fas fa-edit"></i>
                                            </button>
                                        </grid-cell>
                                        <grid-cell>
                                            <button v-if="factura.estado === 'RADICADO'" class="btn btn-sm btn-datatable btn-transparent-dark" @click="showCarteraComponent(factura.id)">
                                                <i class="fas fa-clipboard-list"></i>
                                            </button>
                                        </grid-cell>
                                    </grid-row>
                                </template>
                            </grid-table>
                        </template>
                        <template #action-button>
                            <!-- Example split danger button -->
                            <div class="btn-group mb-2 dropleft" v-show="selected.length">
                                <button type="button" class="btn btn-sm btn-info">Acciones</button>
                                <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" @click="massChangeState()">Cambiar Estado a Radicado</a>
                                </div>
                            </div>
                        </template>
                    </data-grid>
                </div>
            </div>
        </div>
        <RegistroCartera ref="carteraComponent"></RegistroCartera>
    </main>
</template>
<script type="text/javascript">

import Swal from "sweetalert2/src/sweetalert2.js";
import facturaService from "../../../services/facturaService";
import paginateMixin from "../../../mixins/PaginateMixin";
import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../components/common/utilities/DataGrid/GridCell";
import RegistroCartera from "./RegistroCartera";

export default {
    components: {RegistroCartera, GridCell, GridRow, GridCellHeader, GridTable, DataGrid},
    mixins : [paginateMixin(facturaService)],
    data: () => ({
        headers : [
            '',
            'Factura',
            'Estado',
            'Eps',
            'Periodo',
            'Actividad',
            'Observaciones',
            'Valor',
            '',
            '',
            ''
        ],
        selected : [],
    }),
    created() {
        this.index();
    },
    methods: {
        async sendInvoiceEps(id_factura) {
            try {
                Swal.fire({
                    title: "Esta seguro de enviar está factura electronicamente ?",
                    showCancelButton: true,
                    icon: "question",
                    confirmButtonText: "Sí, estoy seguro",
                    cancelButtonText: "Cancelar",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return facturaService
                            .sendInvoiceEps(id_factura)
                            .then((response) => {
                                return response.data;
                            })
                            .catch((error) => {
                                Swal.showValidationMessage(`Error: ${error.statusText}`);
                            });
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (result.value.State === "Exitosa") {
                            Swal.fire({
                                text: `Factura electronica enviada con exito`,
                                icon: "success",
                            });
                        } else {
                            let message = "";
                            result.value.ErrorList.forEach((element) => {
                                message += `\n ${element}`;
                            });
                            Swal.showValidationMessage(`Error: ${message}`);
                        }
                        this.getInvoices();
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        async verFacturaElectronica(id_factura) {
            window.open(
                `/api/facturacion-electronica/get-document/${id_factura}`,
                "_blank"
            );
        },
        handleCheck(factura, event){

            if(event.target.checked){
                this.selected.push(factura);
                return;
            }

            this.selected =  this.selected.filter(x=>x!==factura);
        },
        async massChangeState() {
            try{

                let _confirm = confirm('Desea confirmar esta accion en bloque, esta accion no se puede revertir');

                if(!_confirm) return;

                this.LoaderSpinnerShow();

                await facturaService.massChangeState({'facturas' : this.selected});

                this.LoaderSpinnerHide();

                Swal.fire('Accion Ejecutada con exito', '', 'success');

                this.selected = [];

                this.index();

            }catch (e){
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire('Ha ocurrido un error al procesar la solicitud', '', 'error');
            }

        },
        showCarteraComponent(id_factura = ''){
            console.log(id_factura);
            this.$refs.carteraComponent.show();
        }
    }
};
</script>
